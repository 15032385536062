@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@600&display=swap');

:root {
  --black: #202124;
  --grey: #8f8f8f;
  --blue: #308aff;
  --black-gradient: #141414;
  --red: rgb(185, 0, 0);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
}

body {
  height: 100vh;
  background-color: var(--black);
}

.logo {
  width: 40px;
  height: 40px;
}

.header-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100%;
}

.main-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  width: 80%;
  margin: auto;
  height: 60px;
  display: flex;
  align-items: center;
}

.header-origin {
  border-bottom: 1px solid var(--grey);
  background-color: var(--black);
  position: fixed;
  width: 100%;
}

.body {
  height: 100vh;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
}

.s-body {
  background-color: var(--black);
  width: 100%;
}

.s-body .container {
  width: 80%;
  margin: auto;
  padding-top: 80px;
}

.space-left {
  padding-left: 15px;
}

.border {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--grey);
}

.space-left-max {
  padding-left: 30px;
  margin-left: 30px;
}

.inputs {
  height: 45px;
  width: 300px;
  border-radius: 7px;
  color: #000;
  border: none;
  padding-inline: 10px;
  margin-top: 10px;
}

.scroll-list {
  width: 250px;
  border-right: 1px solid var(--grey);
  height: calc(100vh - 61px);
  position: fixed;
}

a {
  text-decoration: none;
}

#selected {
  border: 1px solid var(--grey);
  background-color: var(--black-gradient);
}

.list-items {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-inline: 7px;
  padding: 15px;
  margin-top: 7px;
  border-radius: 5px;
}

.list-items:hover {
  background-color: var(--black-gradient);
}

.cards-board {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-left: 5px;
}

.card-board {
  width: 100%;
  height: 150px;
  border-radius: 7px;
  border: 1px solid var(--grey);
}

.counter-brd {
  display: flex;
  height: calc(100% - 60px);
  justify-content: center;
  align-items: center;
}

#login-button {
  background-color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
}

.content {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
}

.text-description {
  padding-top: 20px;
  font-size: 20px;
}

.imgs-items {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#url {
  transition: 0.15s;
}

#url:hover {
  transform: scale(0.96);
}

.terms-of-use {
  padding-top: 50px;
}

.terms-of-use ul li {
  display: inline-block;
  margin-right: 20px;
  padding-block: 5px;
  cursor: pointer;
  color: var(--blue);
  transition: 0.1s;
}

.terms-of-use ul li:hover {
  transform: scale(0.95);
  color: #fff;
}

.grid-config {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}

select, option {
  color: #000;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  padding-bottom: 20px;
}

.screens {
  width: 800px;
}

#google-play-icon {
  width: 200px;
  height:60px;
  cursor: pointer
}

.grid-config-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}

.spinner_V8m1{transform-origin:center;animation:spinner_zKoa 2s linear infinite}.spinner_V8m1 circle{stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite}@keyframes spinner_zKoa{100%{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}

@media screen and (max-width: 1570px) {
  .screens {
    width: 700px;
  }
}

@media screen and (max-width: 1450px) {
  .screens {
    width: 650px;
  }
}

@media screen and (max-width: 1400px) {
  .body {
    height: auto;
  }
  .content {
    flex-direction: column-reverse;
  }
  .screens {
    padding-top: 30px;
    width: 600px;
  }
  .content-text {
    padding-top: 10px;
    width: 700px;
  }
  .terms-of-use {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 30px;
  }
  .imgs-items {
    justify-content: center;
  }
  .text-description, #h1-title {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .screens {
    width: 550px;
  }
  .content-text {
    width: 600px;
  }
  #google-play-icon {
    width: 150px;
    height: 45px;
  }
}

@media screen and (max-width: 650px) {
  .screens {
    width: 500px;
  }
  .content-text {
    width: 500px;
  }
}

@media screen and (max-width: 530px) {
  .screens {
    width: 450px;
  }
  .content-text {
    width: 450px;
  }
  .header {
    width: 90%;
  }
  #fc {
    font-size: 25px;
  }
}

@media screen and (max-width: 470px) {
  .screens {
    width: 360px;
  }
  .content-text {
    width: 320px;
  }
}